<template>
  <Loader v-if="crewsQuery.loading.value" />
  <div v-else>
    <Toast v-if="toast.showToast" :title="toast.title" />
    <Modal :open="showModal" :title="modalTitle">
      <form>
        <div class="grid grid-cols-2 gap-4 my-10">
          <div class="w-60">
            <label for="name" class="block text-sm font-medium text-gray-700">Crew Name </label>
            <div
              class="mt-1 border-b border-gray-300 focus-within:border-indigo-600"
              :class="errors.name && 'border-failure-600'"
            >
              <input
                data-test="crew-name-input"
                type="text"
                v-model="name"
                v-bind="nameAttrs"
                class="block w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 sm:text-sm"
                placeholder="Enter Name"
              />
            </div>
            <p v-if="errors.name" class="mt-1 first-letter:capitalize text-failure-500 text-xs">
              {{ errors.name }}
            </p>
          </div>
          <div class="col-span-1 w-60">
            <BaseAutocomplete
              v-if="!editModal"
              label="Select Service"
              v-model="service"
              v-bind="serviceAttrs"
              :searchArray="servicesOptions"
              :fetchOnScroll="false"
              :required="true"
            />
            <p v-if="errors.service" class="mt-1 first-letter:capitalize text-failure-500 text-xs">
              {{ errors.service }}
            </p>
          </div>
          <div class="col-span-1 w-60">
            <label class="text-sm font-normal">Add Equipment </label>
            <Dropdown
              :options="equipmentData.items"
              v-model="selectedItems.equipment"
              :valueField="false"
              :resetDropdown="resetDropdown"
              :editSelectedItems="selectedItems.equipment"
              :fetchOnScroll="true"
              :dataLoading="equipmentData.loading"
              @loadMore="equipmentData.loadMore"
              @filterData="(input) => filterData(input, equipmentData)"
              @removeFilters="(input) => filterData(input, equipmentData)"
            />
          </div>

          <div class="col-span-1 w-60">
            <label class="text-sm font-normal">Add Labor </label>
            <Dropdown
              :options="laborData.items"
              v-model="selectedItems.labor"
              :valueField="false"
              :resetDropdown="resetDropdown"
              :editSelectedItems="selectedItems.labor"
              :fetchOnScroll="true"
              :dataLoading="laborData.loading"
              @loadMore="laborData.loadMore"
              @filterData="(input) => filterData(input, laborData)"
              @removeFilters="(input) => filterData(input, laborData)"
            />
          </div>
          <div v-show="showGradingDropdowns" class="contents">
            <div class="col-span-1 w-60">
              <label class="text-sm font-normal">Add Grading Equipment </label>
              <Dropdown
                :options="gradingEquipmentQuery.items.value"
                v-model="selectedItems.gradingEquipment"
                :valueField="false"
                :resetDropdown="resetDropdown"
                :editSelectedItems="selectedItems.gradingEquipment"
                :fetchOnScroll="true"
                :dataLoading="gradingEquipmentQuery.loading.value"
                @loadMore="gradingEquipmentQuery.loadMore"
                @filterData="(input) => filterData(input, gradingEquipmentQuery)"
                @removeFilters="(input) => filterData(input, gradingEquipmentQuery)"
              />
            </div>

            <div class="col-span-1 w-60">
              <label class="text-sm font-normal">Add Grading Labor </label>
              <Dropdown
                :options="gradingLaborQuery.items.value"
                v-model="selectedItems.gradingLabor"
                :valueField="false"
                :resetDropdown="resetDropdown"
                :editSelectedItems="selectedItems.gradingLabor"
                :fetchOnScroll="true"
                :dataLoading="gradingLaborQuery.loading.value"
                @loadMore="gradingLaborQuery.loadMore"
                @filterData="(input) => filterData(input, gradingLaborQuery)"
                @removeFilters="(input) => filterData(input, gradingLaborQuery)"
              />
            </div>
          </div>
          <div class="col-span-2">
            <div class="contents" v-for="(items, type) in selectedItems">
              <span class="mx-2" v-for="(item, index) in items">
                <InputButton
                  :key="item.id"
                  :index="index"
                  :item="item"
                  :type="type"
                  @updateQuantity="handleUpdateQuantity"
                  @removeMember="handleRemoveMember"
                />
              </span>
            </div>
          </div>
        </div>
      </form>

      <div class="flex justify-end mx-5 py-4">
        <button
          data-test="cancel-button"
          type="button"
          class="mr-auto inline-flex justify-center rounded-md border border-transparent bg-failure-600 ml-3 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-failure-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
          @click="handleCancel"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="mx-2 inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
          @click="handleSubmitData"
        >
          Submit
        </button>
      </div>
    </Modal>
    <div class="flex my-4 px-1 flex-row justify-between items-center">
      <h1 class="text-2xl font-semibold text-gray-900">Crews</h1>
      <BaseButton
        data-test="new-crew-button"
        type="button"
        @click="showModal = true"
        label="New Crew"
        size="small"
      />
    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-primary-50">
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Members
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Service
                  </th>

                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="crew in crewsData" :key="crew.id">
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    {{ crew.title }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ crew.crewMembers.nodes.length }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ crew.service.name }}
                  </td>
                  <td class="relative whitespace-nowrap py-4 text-sm font-medium">
                    <div class="flex">
                      <div class="px-1">
                        <PencilSquareIcon
                          @click="handleEdit(crew)"
                          class="h-5 w-5 text-gray-500 hover:cursor-pointer"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="px-1">
                        <TrashIcon
                          @click="handleDelete(crew)"
                          class="h-5 w-5 text-gray-500 hover:cursor-pointer"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { PencilSquareIcon, TrashIcon } from '@heroicons/vue/20/solid'

import Modal from '@/components/layout/Modal.vue'
import Loader from '@/components/layout/Loader.vue'
import BaseButton from '@/components/layout/BaseButton.vue'
import Toast from '@/components/layout/Toast.vue'
import BaseAutocomplete from '@/components/layout/BaseAutocomplete.vue'
import Dropdown from '@/components/layout/Dropdown.vue'
import InputButton from '@/components/layout/InputButton.vue'

import { useAdminStore } from '@/stores/admin'
import { usePaginatedQuery } from '@/composables/usePaginatedQuery'
import { tableFilterFieldsHandler } from '@/utils/utility_methods'

import CREATE_CREW from '@/graphql/mutations/admin/createCrew.gql'
import DELETE_CREW from '@/graphql/mutations/admin/deleteCrew.gql'
import UPDATE_CREW from '@/graphql/mutations/admin/updateCrew.gql'
import GET_CREWS from '@/graphql/queries/getCrews.gql'
import GET_ASPHALT_EQUIPMENT_DATA from '@/graphql/queries/getAsphaltEquipment.gql'
import GET_ASPHALT_LABOR_DATA from '@/graphql/queries/getAsphaltLabor.gql'
import GET_GRADING_EQUIPMENT_DATA from '@/graphql/queries/getGradingEquipment.gql'
import GET_GRADING_LABOR_DATA from '@/graphql/queries/getGradingLabor.gql'
import GET_CONCRETE_EQUIPMENT_DATA from '@/graphql/queries/getConcreteEquipment.gql'
import GET_CONCRETE_LABOR_DATA from '@/graphql/queries/getConcreteLabor.gql'
import GET_SEALCOAT_EQUIPMENT_DATA from '@/graphql/queries/getSealCoatEquipment.gql'
import GET_SEALCOAT_LABOR_DATA from '@/graphql/queries/getSealCoatLabor.gql'

const showModal = ref(false)
const editModal = ref(false)
const resetDropdown = ref(false)
const selectedItems = ref({ equipment: [], labor: [], gradingEquipment: [], gradingLabor: [] })
const toast = ref({ showToast: false, title: '' })
const modalTitle = ref('Add Crew')

const validationSchema = computed(() => {
  return yup.object({
    name: yup.string().required('Name is required.'),
    service: yup.object().required(),
  })
})

const showGradingDropdowns = computed(
  () => service.value?.name == 'Asphalt' || service.value?.name == 'Concrete'
)

const isSelectedItemsEmpty = computed(() =>
  Object.values(selectedItems.value).every((arr) => arr.length === 0)
)

const servicesOptions = computed(() =>
  store.serviceData?.services?.nodes?.filter((service) => service.name !== 'Striping')
)

const crewsData = computed(() => {
  return crewsQuery.result.value?.crews?.nodes ?? []
})

const {
  defineField,
  errors,
  setValues,
  handleSubmit,
  handleReset: resetFields,
} = useForm({
  validationSchema: validationSchema,
})

const [name, nameAttrs] = defineField('name')
const [service, serviceAttrs] = defineField('service')

const crewsQuery = useQuery(GET_CREWS)
const gradingEquipmentQuery = usePaginatedQuery(GET_GRADING_EQUIPMENT_DATA, 'gradingEquipment')
const gradingLaborQuery = usePaginatedQuery(GET_GRADING_LABOR_DATA, 'gradingLaborers')
const createCrew = useMutation(CREATE_CREW)
const deleteCrew = useMutation(DELETE_CREW)
const updateCrew = useMutation(UPDATE_CREW)
const store = useAdminStore()

const equipmentData = ref({})
const laborData = ref({})

watch(service, (newService, oldService) => {
  if (newService) {
    if (!isSelectedItemsEmpty.value && oldService && newService.name !== oldService.name) {
      resetSelectedItems()
      resetDropdown.value = !resetDropdown.value
    }
    fetchData(newService.name)
  } else {
    equipmentData.value = {}
    laborData.value = {}
  }
})

const handleSubmitData = handleSubmit(async (values) => {
  const membersPayload = createMembersPayload(selectedItems.value)
  if (editModal.value) {
    await updateCrew.mutate({
      input: {
        input: {
          crewId: values.id,
          title: values.name,
          members: membersPayload,
        },
      },
    })
    toast.value = { showToast: true, title: 'Crew updated Successfully!' }
    editModal.value = false
    resetFields()
  } else {
    await createCrew.mutate({
      input: {
        input: {
          serviceId: values.service.id,
          title: values.name,
          members: membersPayload,
        },
      },
    })
    toast.value = { showToast: true, title: 'New Crew created Successfully!' }
  }
  showModal.value = false
  resetSelectedItems()
  resetFields()
  await crewsQuery.refetch()
})

const handleDelete = async (item) => {
  await deleteCrew.mutate({ input: { id: item.id } })
  toast.value = { showToast: true, title: 'Crew removed Successfully!' }
  await crewsQuery.refetch()
}

const handleEdit = (item) => {
  updateSelectedItemsFromQuery(item.crewMembers.nodes)
  editModal.value = true
  modalTitle.value = 'Edit Crew'
  setValues({
    id: item.id,
    name: item.title,
    service: item.service,
  })
  showModal.value = true
}

const fetchData = (serviceType) => {
  let equipmentQuery
  let laborQuery
  let dataKey

  switch (serviceType) {
    case 'Asphalt':
      equipmentQuery = GET_ASPHALT_EQUIPMENT_DATA
      laborQuery = GET_ASPHALT_LABOR_DATA
      dataKey = { equipment: 'asphaltEquipment', labor: 'asphaltLaborer' }
      break
    case 'Concrete':
      equipmentQuery = GET_CONCRETE_EQUIPMENT_DATA
      laborQuery = GET_CONCRETE_LABOR_DATA
      dataKey = { equipment: 'concreteEquipment', labor: 'concreteLaborers' }
      break
    case 'Grading':
      equipmentQuery = GET_GRADING_EQUIPMENT_DATA
      laborQuery = GET_GRADING_LABOR_DATA
      dataKey = { equipment: 'gradingEquipment', labor: 'gradingLaborers' }
      break
    case 'Seal Coating':
      equipmentQuery = GET_SEALCOAT_EQUIPMENT_DATA
      laborQuery = GET_SEALCOAT_LABOR_DATA
      dataKey = { equipment: 'sealCoatEquipment', labor: 'sealCoatLaborers' }
      break
    default:
      equipmentData.value = {}
      laborData.value = {}
      return
  }

  equipmentData.value = usePaginatedQuery(equipmentQuery, dataKey.equipment)
  laborData.value = usePaginatedQuery(laborQuery, dataKey.labor)
}

const handleUpdateQuantity = (index, type, quantity) => {
  const items = [...selectedItems.value[type]]
  items[index] = { ...items[index], quantity }
  selectedItems.value[type] = items
}

const createMembersPayload = (selectedItems) => {
  const membersPayload = []
  for (const key in selectedItems) {
    const items = selectedItems[key]
    if (Array.isArray(items)) {
      items.forEach((item) => {
        membersPayload.push({
          id: item.id,
          quantity: item.quantity ?? 1,
          multipleServices: key == 'gradingEquipment' || key == 'gradingLabor' ? true : false,
        })
      })
    }
  }

  return membersPayload
}

const resetSelectedItems = () => {
  selectedItems.value = { equipment: [], labor: [], gradingEquipment: [], gradingLabor: [] }
}

const updateSelectedItemsFromQuery = (crewMembers) => {
  if (!crewMembers) return

  crewMembers.forEach(({ member, quantity, multipleServices }) => {
    const newItem = {
      __typename: member.__typename,
      id: member.id,
      name: member.name,
      quantity,
      multipleServices,
    }
    if (member.__typename === 'Equipment') {
      const key = multipleServices ? 'gradingEquipment' : 'equipment'
      selectedItems.value[key].push(newItem)
    } else if (member.__typename === 'Laborer') {
      const key = multipleServices ? 'gradingLabor' : 'labor'
      selectedItems.value[key].push(newItem)
    }
  })
}

const handleRemoveMember = (index, type) => {
  selectedItems.value[type].splice(index, 1)
}

const handleCancel = () => {
  showModal.value = false
  editModal.value = false
  resetSelectedItems()
  resetFields()
  modalTitle.value = 'Add Crew'
}

const filterData = (input, query) => {
  tableFilterFieldsHandler(input, query)
}
</script>
