import { useQuery } from '@vue/apollo-composable'
import { ref, watch, computed } from 'vue'

export function usePaginatedQuery(
  query,
  dataKey,
  initialVariables = {},
  options = {},
  pageSize = 8
) {
  const items = ref([])
  const pageInfo = ref({})
  const hasNextPage = ref(false)
  const variables = ref({ ...initialVariables })

  const currentVariables = computed(() => ({
    ...variables.value,
    first: pageSize,
  }))

  const { result, fetchMore, loading, error } = useQuery(query, currentVariables, options)

  watch(
    result,
    (newValue) => {
      if (newValue && newValue[dataKey]) {
        items.value = newValue[dataKey].nodes
        pageInfo.value = newValue[dataKey].pageInfo
        hasNextPage.value = newValue[dataKey].pageInfo.hasNextPage
      }
    },
    { immediate: true }
  )

  const loadMore = () => {
    if (hasNextPage.value && !loading.value) {
      fetchMore({
        variables: {
          ...variables.value,
          after: pageInfo.value.endCursor,
          first: pageSize,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || !fetchMoreResult[dataKey]) return previousResult
          return {
            ...previousResult,
            [dataKey]: {
              ...fetchMoreResult[dataKey],
              nodes: [...previousResult[dataKey].nodes, ...fetchMoreResult[dataKey].nodes],
              pageInfo: fetchMoreResult[dataKey].pageInfo,
            },
          }
        },
      })
    }
  }

  const updateFilters = (newFilters) => {
    variables.value = { ...variables.value, ...newFilters }
  }

  const filtersApplied = computed(() => {
    return Object.keys(variables.value).some((key) => {
      if (variables.value[key] === '') {
        return false
      }
      return key !== 'first' && variables.value[key] !== initialVariables[key]
    })
  })

  return {
    items,
    loading,
    error,
    loadMore,
    hasNextPage,
    updateFilters,
    filtersApplied,
  }
}
