<template>
  <Modal :open="showModal" title="Add Crew">
    <form class="h-72 flex flex-col justify-between" @submit.prevent="handleCrewSubmit">
      <div class="grid grid-cols-2 gap-4 mb-8 mt-2 mx-8">
        <div class="col-span-1 w-60">
          <BaseAutocomplete
            label="Select Crew"
            data-test="crew-select-input"
            v-model="selectedCrew"
            optionKey="title"
            :searchArray="crewsQuery.items.value"
            :fetchOnScroll="true"
            :dataLoading="crewsQuery.loading.value"
            :required="true"
            @loadMore="crewsQuery.loadMore"
            @filterData="(input) => tableFilterFieldsHandler(input, crewsQuery)"
          />
        </div>
      </div>
      <div class="flex justify-end mx-5 pt-4 pb-10">
        <button
          data-test="cancel-button"
          type="button"
          class="mr-auto inline-flex justify-center rounded-md border border-transparent bg-failure-600 ml-3 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-failure-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
          @click="handleCrewCancel"
        >
          Cancel
        </button>
        <button
          :disabled="!selectedCrew"
          :class="{ 'cursor-not-allowed': !selectedCrew }"
          type="submit"
          class="mx-2 inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
        >
          Submit
        </button>
      </div>
    </form>
  </Modal>
</template>

<script setup>
import { ref } from 'vue'
import Modal from '@/components/layout/Modal.vue'

import BaseAutocomplete from '@/components/layout/BaseAutocomplete.vue'
import { usePaginatedQuery } from '@/composables/usePaginatedQuery'
import GET_CREWS from '@/graphql/queries/getCrews.gql'
import { tableFilterFieldsHandler } from '@/utils/utility_methods'

const selectedCrew = ref(null)
const props = defineProps({
  showModal: { type: Boolean, default: false },
  serviceName: { type: String, default: '' },
})

const emit = defineEmits(['submit', 'closeModal'])

const crewsQuery = usePaginatedQuery(
  GET_CREWS,
  'crews',
  { serviceName: props.serviceName },
  { fetchPolicy: 'cache-and-network' }
)

const handleCrewSubmit = () => {
  emit('submit', selectedCrew.value)
}

const handleCrewCancel = () => {
  emit('closeModal')
  selectedCrew.value = null
}
</script>
