<template>
  <span class="my-1 isolate inline-flex rounded-md shadow-sm">
    <div
      class="relative inline-flex items-center gap-x-1.5 rounded-l-md bg-white px-3 py-1 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
    >
      <span
        data-test="trash-icon"
        @click="handleRemoveItem"
        class="cursor-pointer inline-flex items-center"
      >
        <TrashIcon class="h-4 w-4 text-failure-600" aria-hidden="true" />
      </span>
      <p>{{ item.name }}</p>
    </div>
    <input
      type="number"
      v-model="quantity"
      class="w-16 relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-1 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      placeholder="0"
      @change="handleInput"
    />
  </span>
</template>

<script setup>
import { ref, watch } from 'vue'
import { TrashIcon } from '@heroicons/vue/20/solid'

const quantity = ref(1)
const props = defineProps({
  index: { type: Number, default: 0 },
  item: {
    type: Object,
    default: {},
  },
  type: {
    type: String,
  },
})

const emit = defineEmits(['updateQuantity', 'removeMember'])

watch(
  () => props.item,
  (value) => {
    quantity.value = value.quantity || 1
  },
  { immediate: true }
)

const handleInput = () => {
  emit('updateQuantity', props.index, props.type, quantity.value)
}

const handleRemoveItem = () => {
  emit('removeMember', props.index, props.type)
}
</script>
