<template>
  <div class="my-12 px-4 py-6 bg-primary-50 border rounded-lg border pb-6 border-gray-200">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">{{ title }}</h1>
      </div>
      <div class="flex justify-center items-center mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <div class="mx-3">
          <button
            id="actionsDropdownButton"
            class="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-white focus:outline-none bg-primary-600 rounded-lg border border-gray-200 hover:bg-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
            type="button"
            @click="isDropdownOpen = !isDropdownOpen"
            :disabled="!rowsData.length"
          >
            <svg
              class="-ml-1 mr-1.5 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                clip-rule="evenodd"
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              />
            </svg>
            Actions
          </button>
          <div
            id="actionsDropdown"
            :class="{
              hidden: !isDropdownOpen,
              'absolute z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600': true,
            }"
          >
            <ul
              v-show="!bulkEdit && !bulkDelete"
              class="py-1 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="actionsDropdownButton"
            >
              <li class="cursor-pointer">
                <p
                  @click="bulkEditClick"
                  class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Edit
                </p>
              </li>
            </ul>
            <div v-show="bulkEdit || bulkDelete" class="py-1">
              <p
                @click="cancelBulkEdit"
                class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer"
              >
                Cancel
              </p>
            </div>

            <div v-show="!bulkEdit && !bulkDelete" class="py-1">
              <p
                @click="bulkDeleteClick"
                class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >
                Delete
              </p>
            </div>
          </div>
        </div>
        <div class="w-80 col-span-1">
          <Dropdown
            :options="rowFields"
            :resetDropdown="resetDropdown"
            :fetchOnScroll="true"
            :dataLoading="rowFieldsLoading"
            @update-selected="updateSelectedOptions"
            @loadMore="loadMoreData"
            @filterData="filterData"
            @removeFilters="filterData"
          />
        </div>
        <div class="ml-4">
          <button
            :disabled="selectedOptions.length == 0"
            @click="handleAddRow"
            type="button"
            class="inline-flex items-center rounded-full border border-transparent bg-primary-600 p-2 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <PlusIconMini class="h-4 w-4 hover:cursor-pointer" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>

    <div class="mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
      <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="bg-primary-200">
            <tr>
              <th
                v-for="(column, index) in columns"
                scope="col"
                :key="index"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-3"
                :class="{ 'text-center': column.displayName == 'Action' }"
              >
                {{ column.displayName }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-white" v-if="!rows.length">
              <td :colspan="columns.length">
                <div class="h-52 relative flex justify-center items-center">
                  <p class="px-2 text-lg text-gray-700">No data to display</p>
                </div>
              </td>
            </tr>

            <tr
              v-else
              v-for="row in rows"
              :key="row.id"
              class="border-b border-gray-200 bg-white"
              :class="{ 'hover:bg-primary-50': !row.create && !(row.id === currentRow.editId) }"
            >
              <td
                v-for="(column, index) in columns"
                class="hidden py-4 px-3 text-left text-sm sm:table-cell"
                :class="index == 0 ? 'font-medium text-gray-900' : 'text-gray-500'"
              >
                <div class="flex justify-start" v-if="column.placeholder && row.create">
                  <div class="mt-1 w-28 border-b border-gray-300 focus-within:border-indigo-600">
                    <input
                      type="text"
                      :placeholder="column.placeholderValue"
                      :name="`${name}_${column.name}`"
                      @input="handleChange(row, column)"
                      class="block w-full border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                      @blur="handleBlur($event, row, column)"
                    />
                  </div>
                </div>
                <div class="flex justify-start" v-else-if="column.custom && row.key == 'outside'">
                  <div class="mt-1 w-28 border-b border-gray-300 focus-within:border-indigo-600">
                    <input
                      type="text"
                      :placeholder="column.displayName"
                      :name="`${name}_${column.name}`"
                      v-model="row[column.name]"
                      @input="handleChange(row, column)"
                      class="block w-full border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                    />
                  </div>
                </div>
                <div
                  class="flex justify-start"
                  v-else-if="
                    (column.edit && row.create) ||
                    (column.edit && row.id === currentRow.editId) ||
                    (column.custom && row.id === currentRow.editId) ||
                    (column.edit && bulkEdit)
                  "
                >
                  <div v-if="column.type == 'checkbox'">
                    <input
                      type="checkbox"
                      v-model="row[column.name]"
                      class="h-4 w-4 mr-2 rounded border-gray-300 text-primary-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div
                    v-else
                    class="border-b border-gray-300 focus-within:border-indigo-600"
                    :class="column.type == 'text' ? 'w-28' : 'w-20'"
                  >
                    <input
                      :type="column.type ? 'text' : 'number'"
                      :placeholder="column.displayName"
                      :name="`${name}_${column.name}`"
                      v-model="row[column.name]"
                      @input="handleChange(row, column)"
                      class="p-1 block w-full border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                    />
                  </div>
                </div>
                <div v-else>
                  <p
                    v-if="formatCost(column, row) !== null && formatCost(column, row) !== undefined"
                  >
                    {{ formatCost(column, row) }}
                  </p>
                  <div
                    v-else-if="column.displayName !== 'Action'"
                    class="mt-2 flex items-center w-24 text-failure-700 bg-failure-50 ring-red-600/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                  >
                    Value Missing
                  </div>
                </div>

                <div v-show="!row.create && column.displayName == 'Action'">
                  <div v-if="bulkEdit">
                    <input
                      type="checkbox"
                      value="true"
                      class="h-4 w-4 mr-2 rounded border-gray-300 text-primary-600 focus:ring-indigo-500"
                      v-model="row['bulkEdit']"
                    />
                  </div>
                  <div v-else-if="bulkDelete" class="text-center">
                    <input
                      type="checkbox"
                      value="true"
                      class="h-4 w-4 mr-2 rounded border-gray-300 text-primary-600 focus:ring-indigo-500"
                      v-model="row['bulkDelete']"
                      @change="handleDeleteCheckbox(row)"
                    />
                  </div>
                  <div v-else>
                    <div
                      v-if="calculationsLoading && row.id === currentRow.editId"
                      class="flex justify-center"
                    >
                      <Spinner />
                    </div>
                    <div v-else>
                      <div
                        v-if="row.id === currentRow.editId"
                        class="flex justify-center text-center"
                      >
                        <CheckCircleIcon
                          class="mx-1 h-6 w-6 text-success-500 hover:cursor-pointer"
                          @click="handleEditSave(row)"
                        />
                        <XCircleIcon
                          class="mx-1 h-6 w-6 text-failure-500 hover:cursor-pointer"
                          @click="currentRow.editId = null"
                        />
                      </div>
                      <div v-else class="flex justify-center text-center">
                        <PencilSquareIcon
                          class="mx-1 h-5 w-5 hover:cursor-pointer"
                          @click="
                            () => {
                              currentRow.editId = row.id
                              currentRow.data = { ...row }
                            }
                          "
                        />
                        <TrashIcon
                          class="mx-1 h-5 w-5 hover:cursor-pointer"
                          @click="handleRemoveRow(row)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-show="row.create && column.displayName == 'Action'"
                  class="flex justify-center"
                >
                  <XMarkIcon
                    class="mx-1 h-6 w-6 hover:cursor-pointer"
                    @click="handleAddRowCancel(row)"
                  />
                </div>
              </td>
            </tr>
            <tr v-show="tableTotals">
              <td :colspan="columns.length - totalsDisplayColumn"></td>
              <td class="px-3 py-3 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                {{ formatNumber(tableTotals) }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr v-if="showSave">
              <th
                scope="row"
                :colspan="columns.length"
                class="hidden px-6 py-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
              >
                <button
                  v-if="bulkEdit"
                  :disabled="calculationsLoading"
                  type="button"
                  class="inline-flex items-center text-slate-50 justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-success-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
                  :class="{ 'cursor-not-allowed': calculationsLoading }"
                  @click="emit('handleBulkEdit', name, bulkEditData)"
                >
                  Save
                </button>

                <button
                  v-else
                  :disabled="calculationsLoading"
                  type="button"
                  class="inline-flex items-center text-slate-50 justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-success-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
                  :class="{ 'cursor-not-allowed': calculationsLoading }"
                  @click="emit('saveData', name)"
                >
                  Save
                </button>
              </th>
            </tr>
            <tr v-if="bulkDeleteData.length">
              <th
                scope="row"
                :colspan="columns.length"
                class="hidden px-6 py-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
              >
                <button
                  type="button"
                  class="inline-flex items-center text-slate-50 justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-success-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
                  :class="{ 'cursor-not-allowed': calculationsLoading }"
                  @click="emit('handleBulkDelete', name, 'Delete Data', bulkDeleteData)"
                >
                  Delete
                </button>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import {
  TrashIcon,
  PencilSquareIcon,
  XCircleIcon,
  XMarkIcon,
  CheckCircleIcon,
  PlusIcon as PlusIconMini,
} from '@heroicons/vue/20/solid'

import { formatNumber } from '@/utils/utility_methods'
import Dropdown from '@/components/layout/Dropdown.vue'
import Spinner from '@/components/layout/Spinner.vue'

const selectedOptions = ref([])
const resetDropdown = ref(false)
const tableInputs = ref([])
const currentRow = ref({ editId: null, deleteId: null, data: null })
const current = ref(null)
const bulkEdit = ref(false)
const bulkDelete = ref(false)
const bulkEditData = ref([])
const bulkDeleteData = ref([])
const isDropdownOpen = ref(false)

const emit = defineEmits([
  'addRows',
  'inputChange',
  'saveData',
  'editRow',
  'updateData',
  'removeRow',
  'cancelAddRow',
  'handleBulkEdit',
  'handleBulkDelete',
  'loadMoreData',
  'filterFieldsData',
])
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  columns: {
    type: Array,
    default: [],
  },
  rowFields: {
    type: Array,
    default: [],
  },
  rowsData: {
    type: Array,
    default: [],
  },
  initialData: {
    type: Object,
    default: {},
  },
  name: {
    type: String,
    default: '',
  },
  showSave: { type: Boolean, default: false },
  calculationsLoading: { type: Boolean, default: false },
  rowFieldsLoading: { type: Boolean, default: false },
  totalsDisplayColumn: { type: Number, default: 2 }, //totalsDisplayColumn prop is to map totalCost column
})

const rows = ref(props.rowsData.map((item) => ({ ...item })))
watch(
  () => props.rowsData,
  (data) => {
    rows.value = data.map((item) => ({ ...item }))
  }
)

onMounted(() => {
  tableInputs.value = props.columns.filter((item) => item.edit || item.custom)
})

const tableTotals = computed(() => {
  return props.rowsData.reduce((sum, row) => sum + row.totalCost, 0)
})

const initialValues = props.columns
  .filter((item) => item.initialValue !== undefined)
  .reduce((acc, cur) => ({ ...acc, [cur.name]: cur.initialValue }), {})

const handleAddRow = () => {
  emit(
    'addRows',
    props.name,
    selectedOptions.value.map((item) =>
      item?.name?.toLowerCase().includes('outside')
        ? { ...item, ...initialValues, create: true, key: 'outside' }
        : { ...item, ...initialValues, create: true }
    )
  )
  selectedOptions.value = []
  resetDropdown.value = !resetDropdown.value
}

const handleChange = (row) => {
  let valuesFilled = tableInputs.value
    .filter((item) => item.type !== 'checkbox')
    .every((v) => row[v.name])
  if (valuesFilled) {
    current.value = row
    emit('updateData', props.name, current, currentRow.value.editId)
  }

  if (bulkEdit.value) {
    const rowIndex = rows.value.findIndex((r) => r.id === row.id)
    if (rowIndex === -1) return
    rows.value[rowIndex].bulkEdit = true
    // Check if the row already exists in `bulkEditData`
    const existingRowIndex = bulkEditData.value.findIndex((r) => r.id === row.id)
    if (existingRowIndex !== -1) {
      bulkEditData.value.splice(existingRowIndex, 1, row)
    } else {
      bulkEditData.value.push(row)
    }
  }
}

const handleEditSave = (row) => {
  currentRow.value.editId = null
  emit('editRow', props.name, [{ id: row.id, ...row }], row)
}

const handleRemoveRow = (row) => {
  if (props.name == 'equipmentData' || props.name == 'gradingEquipmentData') {
    emit('removeRow', props.name, 'Delete Equipment Data', [row])
  } else if (props.name == 'laborerData' || props.name == 'gradingLaborerData') {
    emit('removeRow', props.name, 'Delete Laborer Data', [row])
  } else if (props.name == 'miscellaneousCostData') {
    emit('removeRow', props.name, 'Delete MiscellaneousCost Data', [row])
  } else if (props.name == 'constructionMaterialsData') {
    emit('removeRow', props.name, 'Delete Construction Materials Data', [row])
  }
}

const handleAddRowCancel = (row) => {
  emit(
    'cancelAddRow',
    props.name,
    row.id,
    rows.value.filter((item) => item.id !== row.id)
  )
}

const updateSelectedOptions = (options) => {
  selectedOptions.value = options
}

const handleBlur = (e, row, column) => {
  row[column.name] = e.target.value
}

const formatCost = (column, row) => {
  let value =
    column.nestedKey && row[column.name] == null
      ? row[column.nestedKey][column.name]
      : row[column.name]

  return column.cost ? (value ? formatNumber(value) : 0) : value ?? column.initialValue
}

const cancelBulkEdit = () => {
  bulkEdit.value = false
  bulkDelete.value = false
  isDropdownOpen.value = false
}

const bulkEditClick = () => {
  if (props.rowsData.length) {
    bulkEdit.value = true
  }
  isDropdownOpen.value = false
}

const bulkDeleteClick = () => {
  if (props.rowsData.length) {
    bulkDelete.value = true
  }
  isDropdownOpen.value = false
}

const handleDeleteCheckbox = (row) => {
  const rowIndex = bulkDeleteData.value.findIndex((id) => id === row.id)
  if (rowIndex == -1) {
    bulkDeleteData.value.push(row.id)
  } else {
    bulkDeleteData.value = bulkDeleteData.value.filter((itemId) => itemId !== row.id)
  }
}

const loadMoreData = () => {
  emit('loadMoreData')
}

const filterData = (input) => {
  emit('filterFieldsData', input)
}
</script>
