<template>
  <Sidebar v-if="!isLoginRoute">
    <router-view />
  </Sidebar>
  <router-view v-else />
</template>

<script setup>
import { computed, ref, onBeforeUnmount, watch } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { useUserStore } from '@/stores/user'
import Sidebar from '@/components/layout/Sidebar.vue'

const router = useRouter()
const userStore = useUserStore()

const sessionCheckInterval = ref(null)

const checkIfUserIsAlive = async () => {
  try {
    await axios.post('/sessions/alive')
  } catch (error) {
    console.log('Error:', error)
    await userStore.logoutUser()
  }
}

const startSessionCheck = () => {
  if (!sessionCheckInterval.value) {
    checkIfUserIsAlive()
    sessionCheckInterval.value = setInterval(checkIfUserIsAlive, 120000)
  }
}

const stopSessionCheck = () => {
  if (sessionCheckInterval.value) {
    clearInterval(sessionCheckInterval.value)
    sessionCheckInterval.value = null
  }
}

const isLoginRoute = computed(() => {
  return router.currentRoute.value.meta.isPublic
})

watch(
  () => router.currentRoute.value.path,
  () => {
    if (isLoginRoute.value) {
      stopSessionCheck()
    } else {
      startSessionCheck()
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  stopSessionCheck()
})
</script>
