<template>
  <button
    :class="[
      'flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none',
      success
        ? 'border-blue-600 text-primary-600 bg-transparent hover:bg-primary-100 active:bg-primary-100'
        : 'text-white bg-primary-600 hover:bg-primary-700',
      {
        'w-full': fullWidth,
        'py-2 px-5': size === 'small' && !fullWidth,
        'm-w-20 py-3 px-7': size === 'large' && !fullWidth,
      },
    ]"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'default',
    },
    success: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
